:root {
  --primary: #111112;
  --accent: #d8e4fb;
  --secondary: #d8e4fb;
  --success: #058c4b;

  /* Sidebar Customisation */
  --sidebar: #111112;
  --sidebar-width: 256px;
  --menu-item-height: 40px;
  --menu-item-font-size: 14px;
}
