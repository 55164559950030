/* Local CSS Imports */
@import url('./ant-menu.css');
@import url('./ant-layout.css');
@import url('./ant-table.css');
@import url('./layouts.css');

:root {
  /* Overrides */
  --primary: #fadb14;
  --sidebar: #f0f0f0;
  --menu-item-height: 85px;
  --menu-item-font-size: 24px;
  --sidebar-width: 256px;

  /* Project-Specific */
  --text: black;
  --sunrise-yellow: #ffffb8;
  --very-light-pink: #d9d9d9;
  --blue: #3b7ae9;

  --logo-width: 370px;
}

* {
  font-family: 'Poppins', sans-serif;
}

main {
  margin: 0px;
}

.logo-container {
  width: var(--logo-width) !important;
}

.buttons-container {
  width: calc(100% - var(--logo-width)) !important;
  margin-right: 0px !important;
}

.header-container {
  height: 100%;
}
