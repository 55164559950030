@import url(./vars.css);

html,
body,
#__next {
  padding: 0;
  margin: 0;
  display: flex;
  flex-grow: 1;
}

.router-loading-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

main {
  margin: 12px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.text-on-dark {
  color: white;
}

.text-on-light {
  color: black;
}

.ant-layout-content {
  overflow-y: scroll;
}
