.ant-menu {
  padding: 4px;
}

.ant-menu-item {
  border-radius: 5px;
  color: var(--text);
}

.propel-menu-item-active {
  background-color: var(--sunrise-yellow);
}

.ant-menu-item-selected  a {
  color: var(--text);
}
